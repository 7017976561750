import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../../../components/layouts"
import Banner from "../../../../components/resources/blog/Banner"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import SEO from '../../../../components/common/SEO';
import BlogList from "../../../../components/resources/blog/BlogList"
// Query for the Course content in Prismic
export const query = graphql`
  query CategoryQuery($uid: String) {
    seo: site {
      siteMetadata {
        url
        twitterUsername
      }
    }
    blogList: prismic {
      allBlogs(sortBy: date_created_DESC) {
        totalCount
        edges {
          node {
            _linkType
            title
            social_description
            date_created
            thumbnail
            _meta {
              uid
            }
            author {
              ... on PRISMIC_Author {
                first_name
                last_name
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    categoryDetail: prismic {
      allCategorys(uid: $uid) {
        edges {
            node {
                _meta {
                    uid
                }
                meta_title
                meta_description
                social_title
                social_description
                social_image
                title
            }
        }
      }
    }
    recentBlogs: prismic {
      allBlogs(first: 3, sortBy: date_created_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            thumbnail
            date_created
          }
        }
      }
    }
    categoryList: prismic {
      allCategorys {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const Category = props => {
    console.log(props);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const category = props.data.categoryDetail.allCategorys
  const blogList = props.data.blogList.allBlogs
  const categories = props.data.categoryList.allCategorys
  const recents = props.data.recentBlogs.allBlogs

  let recentPosts = []
  let categoryString = ""
  let bannerContent = {
    categories: "categoryString",
    title: "content.title[0].text",
    author: "content.author.first_name +  + content.author.last_name",
    date: "blogMonth +  + blogDay +  + blogYear",
  }
  let content
  let seo
  let body
  let categoryElements
  if (category.edges[0] != undefined) {
      console.log(category);
    content = category.edges[0].node
    // Recent posts
    if (recents != null) {
      const posts = recents.edges
      posts.forEach((post, index) => {
        // const postDate = post.node.date_created
        // const postDay = postDate.getDate()
        // const postMonth = monthNames[postDate.getMonth()]
        // const postYear = postDate.getFullYear()
        const postImage =
          post.node.social_image != null ? post.node.social_image.url : ""
        recentPosts.push(
          <div className="post_item" key={index}>
            <img src={postImage} alt="" />
            <div className="media-body">
              <Link to={`/resources/blog/${post.node._meta.uid}`}>
                <h3>{post.node.title[0].text}</h3>
              </Link>
              <a href="#" className="entry_post_info">
                {post.node.date_created}
                {/* {postMonth +
                                        " " +
                                        postDay +
                                        ", " +
                                        postYear} */}
              </a>
            </div>
          </div>
        )
      })
    }

    categoryElements = [];
    if (categories != null) {
      const categoryList = categories.edges
      categoryList.forEach(category => {
        categoryElements.push(
          <Link to={'/resources/blog/categories/' + category.node._meta.uid} >
            {category.node.title[0].text}
          </Link>
        )
      })
    }

    // Banner content
    bannerContent = {
      categories: categoryString,
      title: content.title[0].text,
      author: content.author
        ? content.author.first_name + " " + content.author.last_name
        : "",
      date: content.date_created,
    }

    seo = {
      title: content.meta_title,
      description: content.meta_description,
      image: (content.social_image) ? content.social_image.url : null,
      pathname: '/resources/blog/' + content._meta.uid,
      article: true,
    }
  }

  return (
    <Layout>
      <SEO {...seo} />
      <Banner bannerContent={bannerContent} />
      <BlogList blogs={blogList.edges} recentBlogs={props.data.recentBlogs} categoryList={props.data.categoryList} />
    </Layout>
  )
}

export default Category
